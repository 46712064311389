<template>
  <p v-if="loading">Carregando...</p>

  <div :class="authenticated && 'content'" v-if="!loading">  
    <Header v-if="authenticated"
            :logo="company.logoUrl"
            :userPhoto="user.imagemUrl"
            :userName="user.nomeFantasia || user.razaoSocial || 'Usuário Interno'"
            /> 
    <div class="subheader" v-if="authenticated">
        <h2 class="page-title">
          {{this.$route.meta.title}}
        </h2>
    </div>    
    <div :class="authenticated && 'container'">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <Footer v-if="authenticated"
            :company="company"
            :companies="companies"
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip,} from 'bootstrap/dist/js/bootstrap.esm.min.js';
import {isAuthenticated,companyPath} from '@/services/auth';
import {isMobile,personKey,empresaKey} from '@/services/util';
import {getItemOrFetch,setItem} from '@/services/localdb';


export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      money:{
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
        disableNegative: true,
        allowBlank: true,
      },
      authenticated: isAuthenticated(),
      company: {},
      companies: [],
      user:{},
      loading: false,
    }
  },
  // mixins: [refreshPageMixin],
  async created(){

    if(isAuthenticated() && (!this.user.codigoLojista || !this.company.codigoEmpresa)) {
      let userData = await getItemOrFetch(personKey, '/v1/lojistas');
      let company = await getItemOrFetch(empresaKey, '/v1/empresas');

      this.user = userData;
      this.company = company.filter(f=>f.matriz)[0];
      this.companies = company;

      setItem('company',JSON.stringify(this.company));
    }
  },
  mounted(){
    this.$nextTick(function () {
      console.log('app mounted');
      

      !isMobile() && Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => new Tooltip(tooltipNode));
    })
  },
  beforeMount() {
      if(location.hostname.includes('localhost')) {
        let head = document.getElementsByTagName('head')[0];
        let style = document.createElement("link");
        //de alguma forma, gerar remoto no servidor e informar a URL no 
        //lugar do local.css
        style.setAttribute('href','/local.css'); 
        style.setAttribute('rel','stylesheet'); 

        head.append(style);
      }
  },
}

</script>


<style>
@import '~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

:root {
    --primary-color: #fff;
    --secondary-color: #fff;
    --primary-lighter: #fff;
   --bg-color: #f0f2f5;
   --darker: #111;
   --dark: #2c3e50;
   --regular: #666;
   --light: #e6e6e6;
   --lighter: #eee;
   --white: #FFF;
    /* danger: #f4516c; */
   --danger: #e60014;
   --success: #40cd28;
    /* // success: #34bfa3; */
    /* // warning: #ffb822;*/
   --warning: #f2c832;
    
   --info: #24cbff;
    /* // info: #36a3f7, */
   --link: #3483fa;
  }
html,body{
  background: var(--bg-color);
  font-family: "Montserrat", sans-serif;

}
.moveUp-enter-active{
  animation: fadeIn 1s ease-in;
}
@keyframes moveUp {
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(-400px);
  }
}
.moveUp-leave-active{
  animation: moveUp 0.3s ease-in;
}

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to{
  opacity: 0;
}
.content > .container{
  padding-top: 1.5rem;
  min-height: 300px
}
.nav-link {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
a {
    color: var(--primary-color);
    text-decoration: underline;
}
.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.btn-primary:hover {
    background-color: var(--primary-lighter);
    border-color: var(--primary-lighter);
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
    background-color: var(--primary-lighter);
    border-color: var(--primary-lighter);
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.nav-link:hover, .nav-link:focus {
    color: var(--primary-lighter) ;
}
#app {
  font-family: "Montserrat", sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark);
}

#app a{
  text-decoration: none;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: var(--dark);
}


.content{
  padding-top: 56px;
  background-color: var(--bg-color);

}
.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.form-check-input:focus {
    /* border-color: #86b7fe; */
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.pull-right{
  float: right;
}
.pull-left{
  float: left;
}


.btn {
    border: none;
    font-size: 0.75rem;
    line-height: 1.1;
    font-weight: 600;
    position: relative;
    outline: none;
    padding: 4px 24px 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 46px;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
}
  button > svg,
  a > svg{
    margin-right: 6px;
  }
  .title {
    font-size: 30px;
    line-height: 1.3;
    color: #222222;
    margin: 0;
    padding: 0;
    font-weight: 600;
}
.dropdown-item{
    font-size: 0.75rem;
    line-height: 1.1;
    font-weight: 600;
    padding: 4px 14px 4px!important;
    height: 32px;
    display: inline-flex;
    align-items: center;
}

.pr-1{
  padding-right: 0.25rem !important;
}
.form-check,.form-check-label{
  cursor: pointer;
}
.modal-backdrop.show {
    opacity: 0.2;
}
label.form-label{
    font-weight: 600;
    font-size:0.75rem;
  }
  .mt-17{
    margin-top: 1.7rem !important;
  }

.form-control,.input-group-text{
  font-size: 0.75rem
}
.bg-white{
  background: var(--bg-color);
}
.btn{
  font-size: 0.75rem;
  text-transform: uppercase;
}
.page-title{
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin:0;
}
.subheader{
  display: block;
  width:100%;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
  background: #f8f8f8;
  border-bottom: 1px solid #f0f0f0;
}
.text-center{
    text-align:center;
  }
  .text-right{
    text-align:right;
  }
  .text-left{
    text-align:left;
  }
  .btn-primary:disabled, .btn-primary.disabled,.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
@media (min-width: 992px){
  .sticker{
  position: sticky;
    z-index: 1;
    top: 56px;
}
}
@media (max-width: 992px){
  .form-control,.input-group-text{
    font-size: 1rem
  }
  label.form-label{
    font-size: 1rem;
  }
  .btn{
    font-size: 1rem;
  }
}
@media (max-width: 576px){
  .page-title{
    font-size: 1rem;
  }
}
</style>
